import { ThemeProvider } from "styled-components";
import React, { Component } from "react";

import io from "socket.io-client";

import { Provider } from "mobx-react";
import { observer } from "mobx-react";

import AppStore from "./store";
import colors from "tailwindcss/colors";
import notifySound from "./media/notify.mp3";

import useIsWebView from "./Hooks/useIsWebView";

import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
  Link,
  useLocation,
} from "react-router-dom";

import Header from "./Header";
import Search from "./Search";
import Pricing from "./Pricing";

import Dashboard from "./Dashboard";

import Tool from "./Core/Tool";
import Chat from "./Core/Chat";
import AdminTool from "./Core/AdminTool";

import Login from "./Login/Login";

import Profile from "./Profile/";
import LoginSuccess from "./Login/Success";

import "./styles/index.scss";
import "./styles/App.scss";
import toast, { Toaster } from "react-hot-toast";
import ForgotPassword from "./Login/ForgotPassword";
import NavixScribe from "./Core/NavixScribe";
import { navixScribeStore } from "./Core/NavixScribe/NavixScribeStore";

import FloatingBanner from "./Components/FloatingBanner";

if (!window.store) {
  window.store = new AppStore();
}

@observer
class App extends Component {
  constructor(props) {
    super(props);
    this.socket = null;
  }

  componentDidMount() {
    this.connectToSocket();
  }

  componentWillUnmount() {
    this.socket?.disconnect();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!!window.store.profile.status) {
      if (!this.socket?.connected) {
        this.connectToSocket();
      }
    } else {
      this.socket?.disconnect();
    }
  }

  connectToSocket() {
    if (!!window.store.profile.status) {
      console.log(process.env.REACT_APP_WEBSOCKET_URL);
      this.socket = io(process.env.REACT_APP_WEBSOCKET_URL, {
        auth: {
          token: localStorage.getItem("token"),
        },
      });

      this.socket.on(
        "assembly-transcription-webhook-success",
        ({ message, history }) => {
          new Audio(notifySound).play();
          const { inputActiveRecord, setInputActiveRecord, setTriggerSection } =
            navixScribeStore;

          console.log("WebSocket Success - Incoming History:", history);

          if (inputActiveRecord?._id === history._id) {
            setInputActiveRecord(history);
            setTriggerSection("Input");
            toast.success((t) => <span>{message},&nbsp; !</span>, {
              duration: 5000,
            });
          }
        }
      );

      this.socket.on("assembly-transcription-webhook-error", (error) => {
        const message =
          typeof error === "string"
            ? error
            : error.message || "An unknown error occurred";
        const { inputActiveRecord, setInputActiveRecord, setTriggerSection } =
          navixScribeStore;

        console.log("WebSocket Error - Incoming Error:", error);

        // update only if the current record is the one that errored out
        if (
          inputActiveRecord &&
          inputActiveRecord.audioTranscriber.transcript_id ===
            error.transcript_id
        ) {
          setInputActiveRecord({
            ...inputActiveRecord,
            audioTranscriber: {
              ...inputActiveRecord.audioTranscriber,
              status: "error",
            },
          });
          setTriggerSection("Input");
          toast.error(message, { duration: 4000 });
        }
      });
    }
  }

  render() {
    return (
      <ThemeProvider theme={colors}>
        <Provider store={window.store} navixScribeStore={navixScribeStore}>
          <Router>
            <Toaster position="top-right" />
            <ConditionalFloatingBanner />
            {window.store.redirect ? (
              <Redirect to={window.store.redirect} />
            ) : null}

            {window.store.isLoggedIn ? (
              <>
                {" "}
                {/* Logged in */}
                <Switch>
                  <Route path="/writing/document">
                    <div />
                  </Route>
                  <Route component={Header} />
                </Switch>
                <Switch>
                  <Route path="/" exact component={Dashboard} />
                  <Route path="/search" exact component={Search} />
                  <Route path="/ai/advisors" component={Chat} />
                  <Route
                    path="/ai/audio/transcriber-new"
                    render={(props) => (
                      <NavixScribe
                        navixScribeStore={navixScribeStore}
                        {...props}
                      />
                    )}
                  />
                  <Route path="/ai/" component={Tool} />
                  <Route path="/my-profile" component={Profile} />
                  <Route path="/signup/failed" component={Profile} />
                  <Route path="/login/success" component={LoginSuccess} />

                  {window.store.profile.accountType === "admin" ? (
                    <>
                      {" "}
                      {/* Admin only */}
                      <Route path="/admin" component={AdminTool} />
                    </>
                  ) : (
                    <Redirect to="/" />
                  )}
                </Switch>
              </>
            ) : (
              <>
                {" "}
                {/* Not Logged in */}
                <Switch>
                  <Route path="/login/success" component={LoginSuccess} />
                  <Route path="/forgot-password" component={ForgotPassword} />
                  <Route path="/" exact>
                    <Redirect to="/login" />
                  </Route>
                  <Route path="/" component={Login} />
                </Switch>
              </>
            )}
          </Router>
        </Provider>
      </ThemeProvider>
    );
  }
}

const ConditionalFloatingBanner = () => {
  const location = useLocation();
  const isWebView = useIsWebView();
  return location.pathname !== "/login" && !isWebView ? (
    <FloatingBanner />
  ) : null;
};

export default App;
