import { MenuAlt2Icon, ViewListIcon } from "@heroicons/react/solid";

const obj = {
  title: "NavixScribe",
  desc: "Our latest enhanced NavixScribe! Dictation and transcription. Clinical notes in selected formats. Ask questions related to your content. Generate summaries.",
  category: "Clinical",
  Icon: ViewListIcon,
  // tags: [],
  permissions: ["user"],

  fromColor: "gray-500",
  toColor: "navix-turquoise",

  to: "/ai/audio/transcriber-new",
  api: "/ai/audio/transcriber",

  showHistory: true,
};

export default obj;
