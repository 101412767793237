import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { computed } from "mobx";
import MainBody from "./Components/Body";
import { Helmet } from "react-helmet";
import Modal from "./Components/Modal";
import { observer, inject } from "mobx-react";

@inject("store")
@observer
class Body extends Component {
  state = {
    showModal: false,
    videoSrc: "https://www.youtube.com/embed/tK712pTkVjk?si=Y6P4fN5BzLS8zEmD",
  };

  async componentDidMount() {
    await this.checkFirstLogin();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      await this.checkFirstLogin();
    }
  }

  checkFirstLogin = async () => {
    const { store } = this.props;
    await store.refreshTokenAndProfile();

    if (store.profile && !store.profile.firstLoginCompleted) {
      this.setState({ showModal: true });
      await store.api.patch(`/user/${store.profile._id}`, {
        firstLoginCompleted: true,
      });
      store.profile.firstLoginCompleted = true;
    } else {
      this.setState({ showModal: false, videoSrc: "" });
    }
  };

  closeModal = () => {
    this.setState({ showModal: false, videoSrc: "" });
  };

  // openModal = () => { not currently used at the moment.
  //   this.setState({
  //     showModal: true,
  //     videoSrc: "https://www.youtube.com/embed/LXb3EKWsInQ?si=UAmX0jIsleCtXf5Q",
  //   });
  // };

  @computed get permissions() {
    return this.props.store.tools.filter((tool) => {
      return (
        this.props.store.hasCurrentToolAccess(tool.to) &&
        tool.permissions.some((permission) =>
          this.props.store.profile.permissions.includes(permission)
        )
      );
    });
  }

  @computed get isAdmin() {
    return this.props.store.profile.accountType === "admin";
  }

  @computed get adminTools() {
    return this.props.store.adminTools;
  }

  @computed get beta() {
    return this.permissions.filter((tool) => tool.category === "Beta");
  }

  @computed get business() {
    return this.permissions.filter((tool) => tool.category === "Business");
  }

  @computed get advisor() {
    const advisors = this.permissions
      .filter((tool) => {
        const hidden = ["Treatment Planning", "Compliance", "Human Resource"];

        return tool.category === "Advisor" && !hidden.includes(tool.title);
      })
      .map((tool) => {
        if (tool.title === "Behavioral Health") {
          tool.title = "Advisors & Resource Libraries";
          tool.desc =
            "Chat with our AI Advisors on topics including Behavioral Health, Compliance, Treatment Planning and Human Resources.";
        }

        return tool;
      });

    return advisors;
  }

  @computed get clinical() {
    return this.permissions.filter((tool) => tool.category === "Clinical");
  }

  @computed get content() {
    return this.permissions.filter((tool) => tool.category === "Content");
  }

  @computed get rcm() {
    return this.permissions.filter((tool) => tool.category === "RCM");
  }

  @computed get research() {
    return this.permissions.filter((tool) => tool.category === "Research");
  }

  @computed get audio() {
    return this.permissions.filter((tool) => tool.category === "Audio");
  }

  render() {
    return (
      <>
        <Helmet>
          <title>{`Tools - NavixAI`}</title>
        </Helmet>
        <MainBody className="px-4 py-4 md:px-28 md:py-8 lg:py-12 ">
          <Modal
            variant="video"
            open={this.state.showModal}
            onClose={this.closeModal}
          >
            <h2 className="text-xl font-bold mb-2">Welcome to Navix AI!</h2>
            <p className="mb-2">Watch this video to get started:</p>
            <div
              className="relative w-full"
              style={{
                paddingBottom: "56.25%",
                height: 0,
                overflow: "hidden",
                maxWidth: "100%",
                background: "#000",
              }}
            >
              <iframe
                src={this.state.videoSrc}
                frameBorder="0"
                allowFullScreen
                title="NavixScribe guide"
                className="absolute top-0 left-0 w-full h-full"
                style={{ border: 0 }}
              ></iframe>
            </div>
          </Modal>

          {this.business.length ? (
            <>
              <Title title="Business Management" />
              <Grid>
                {this.business.map((tool, index) => (
                  <Tool
                    key={index}
                    group={tool.category}
                    title={tool.title}
                    to={tool.to}
                    Icon={tool.Icon}
                    desc={tool.desc}
                    fromColor={tool.fromColor}
                    toColor={tool.toColor}
                  />
                ))}
              </Grid>
              <Divider />
            </>
          ) : null}

          {this.advisor.length ? (
            <>
              <Title title="Advisors" />
              <Grid>
                {this.advisor.map((tool, index) => (
                  <Tool
                    key={index}
                    group={tool.category}
                    title={tool.title}
                    to={tool.to}
                    Icon={tool.Icon}
                    desc={tool.desc}
                    fromColor={tool.fromColor}
                    toColor={tool.toColor}
                  />
                ))}
              </Grid>
              <Divider />
            </>
          ) : null}

          {this.clinical.length ? (
            <>
              <Title title="Clinical & Medical" />
              <Grid>
                {this.clinical.map((tool, index) => (
                  <Tool
                    key={index}
                    group={tool.category}
                    title={tool.title}
                    to={tool.to}
                    Icon={tool.Icon}
                    desc={tool.desc}
                    fromColor={tool.fromColor}
                    toColor={tool.toColor}
                  />
                ))}
              </Grid>
              <Divider />
            </>
          ) : null}

          {this.content.length ? (
            <>
              <Title title="CRM & Marketing" />
              <Grid>
                {this.content.map((tool, index) => (
                  <Tool
                    key={index}
                    group={tool.category}
                    title={tool.title}
                    to={tool.to}
                    Icon={tool.Icon}
                    desc={tool.desc}
                    fromColor={tool.fromColor}
                    toColor={tool.toColor}
                  />
                ))}
              </Grid>
              <Divider />
            </>
          ) : null}

          {this.research.length ? (
            <>
              <Title title="Research & Reference" />
              <Grid>
                {this.research.map((tool, index) => (
                  <Tool
                    key={index}
                    group={tool.category}
                    title={tool.title}
                    to={tool.to}
                    Icon={tool.Icon}
                    desc={tool.desc}
                    fromColor={tool.fromColor}
                    toColor={tool.toColor}
                  />
                ))}
              </Grid>
              <Divider />
            </>
          ) : null}

          {this.rcm.length ? (
            <>
              <Title title="Revenue Cycle Management (RCM)" />
              <Grid>
                {this.rcm.map((tool, index) => (
                  <Tool
                    key={index}
                    group={tool.category}
                    title={tool.title}
                    to={tool.to}
                    Icon={tool.Icon}
                    desc={tool.desc}
                    fromColor={tool.fromColor}
                    toColor={tool.toColor}
                  />
                ))}
              </Grid>
              <Divider />
            </>
          ) : null}

          {this.audio.length ? (
            <>
              <Title title="Audio Processor" />
              <Grid>
                {this.audio.map((tool, index) => (
                  <Tool
                    key={index}
                    group={tool.category}
                    title={tool.title}
                    to={tool.to}
                    Icon={tool.Icon}
                    desc={tool.desc}
                    fromColor={tool.fromColor}
                    toColor={tool.toColor}
                  />
                ))}
              </Grid>
              <Divider />
            </>
          ) : null}
          {this.isAdmin && (
            <>
              <Title title="Admin Tools" />
              <Grid>
                {this.adminTools.map((tool, index) => (
                  <Tool
                    key={index}
                    group="Admin"
                    title={tool.title}
                    to={tool.to}
                    Icon={tool.Icon}
                    desc={tool.desc}
                    fromColor={tool.fromColor}
                    toColor={tool.toColor}
                  />
                ))}
              </Grid>
              <Divider />
            </>
          )}
        </MainBody>
      </>
    );
  }
}

export const Divider = () => (
  <div className="divide-y-2 divide-dashed divide-gray-300 py-4 md:py-6">
    {" "}
    <div></div>
    <div></div>
  </div>
);

export const Title = ({ title }) => (
  <h2 className="text-xl sm:text-2xl md:text-3xl text-gray-700 mb-4 md:mb-6">
    {title}
  </h2>
);

export const Grid = ({ children }) => (
  <div className="grid grid-cols-1 gap-8 mt-4 lg:grid-cols-2 xl:grid-cols-3 ">
    {children}
  </div>
);

export const Tool = ({ Icon, title, desc, to, group, fromColor, toColor }) => (
  <Link to={to || "/"} className="flex relative ">
    <div
      className={`bg-gray-200 flex-1 rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg text-gray-500 cursor-pointer border border-gray-400 md:flex relative transform hover:scale-105  hover:text-black`}
    >
      <div className="p-4">
        <div
          className={`uppercase tracking-wide text-sm text-${fromColor} font-semibold leading-none`}
        >
          {group || "New"}
        </div>
        <div
          href="#"
          className="block text-lg xl:text-xl 2xl:text-2xl leading-tight font-medium text-black leading-none"
        >
          {title}
        </div>
        <p className="mt-1 pr-1 text-sm ">{desc} </p>
      </div>
    </div>
  </Link>
);

export default withRouter(Body);
