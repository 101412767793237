import { inject, observer } from "mobx-react";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import RecordPlugin from "wavesurfer.js/plugins/record";
import useWavesurfer from "../Hooks/useWavesurfer";

const WaveSurferRecorder = inject(
  "store",
  "navixScribeStore"
)(
  observer(
    forwardRef(
      (
        {
          store,
          navixScribeStore,
          onAudioUrl,
          onBlob,
          recording,
          isRecording,
          ...props
        },
        ref
      ) => {
        const containerRef = useRef();
        const [recordPlugin, setRecordPlugin] = useState(null);
        const [audioChunks, setAudioChunks] = useState([]);
        const wavesurfer = useWavesurfer(containerRef, props);

        const onRecordClick = useCallback(() => {
          if (recordPlugin.isRecording()) {
            recordPlugin.stopRecording();
            navixScribeStore.stopRecording();
          } else {
            recordPlugin.startRecording();
            navixScribeStore.startRecording();
          }
        }, [recordPlugin, navixScribeStore]);

        useEffect(() => {
          if (!wavesurfer) return;

          setRecordPlugin(
            wavesurfer.registerPlugin(
              RecordPlugin.create({
                audioBitsPerSecond: 32000,
                mimeType: "audio/webm;codecs=opus",
              })
            )
          );
        }, [wavesurfer]);

        useEffect(() => {
          if (!recordPlugin) return;

          const subscriptions = [
            recordPlugin.on("record-start", () => recording(true)),
            recordPlugin.on("record-end", (blob) => {
              setAudioChunks((prev) => [...prev, blob]);
              recording(false);
            }),
          ];

          return () => {
            subscriptions.forEach((unsub) => unsub());
            store.audioBlob = null;
          };
        }, [recordPlugin]);

        useEffect(() => {
          if (!audioChunks.length) return;

          const blob = new Blob(audioChunks, { type: "audio/webm" });
          const url = URL.createObjectURL(blob);

          onAudioUrl(url);
          onBlob(blob);

          console.log(audioChunks);
        }, [audioChunks]);

        const clearAudioChunks = () => {
          setAudioChunks([]);
        };

        useImperativeHandle(ref, () => ({
          clearAudioChunks,
        }));

        return (
          <>
            <button
              className="hidden sm:inline-flex items-center bg-gray-500 px-2 py-1 rounded-md text-white text-center text-md transition hover:from-gray-700 hover:to-gray-800"
              onClick={onRecordClick}
            >
              {isRecording ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="#DA0000"
                  className={`w-6 h-6 ${isRecording ? "animate-pulse" : ""}`}
                >
                  <path d="M8.25 4.5a3.75 3.75 0 117.5 0v8.25a3.75 3.75 0 11-7.5 0V4.5z" />
                  <path d="M6 10.5a.75.75 0 01.75.75v1.5a5.25 5.25 0 1010.5 0v-1.5a.75.75 0 011.5 0v1.5a6.751 6.751 0 01-6 6.709v2.291h3a.75.75 0 010 1.5h-7.5a.75.75 0 010-1.5h3v-2.291a6.751 6.751 0 01-6-6.709v-1.5A.75.75 0 016 10.5z" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6"
                >
                  <path d="M8.25 4.5a3.75 3.75 0 117.5 0v8.25a3.75 3.75 0 11-7.5 0V4.5z" />
                  <path d="M6 10.5a.75.75 0 01.75.75v1.5a5.25 5.25 0 1010.5 0v-1.5a.75.75 0 011.5 0v1.5a6.751 6.751 0 01-6 6.709v2.291h3a.75.75 0 010 1.5h-7.5a.75.75 0 010-1.5h3v-2.291a6.751 6.751 0 01-6-6.709v-1.5A.75.75 0 016 10.5z" />
                </svg>
              )}
              {isRecording ? "Stop" : "Start"} Recording
            </button>
            <div
              ref={containerRef}
              className={`${isRecording ? "block" : "hidden"} w-full`}
            ></div>
          </>
        );
      }
    )
  )
);

export default WaveSurferRecorder;
