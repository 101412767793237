import { useEffect } from "react";
import useResponsiveStyles from "../Hooks/useResponsiveStyles";

export default function Modal({ open, onClose, children, variant }) {
  const isMobile = useResponsiveStyles();

  const visible = open ? "visible bg-black bg-opacity-20" : "invisible";
  const scale = open ? "scale-100 opacity-100" : "scale-125 opacity-0";

  const modalStyles =
    variant === "video"
      ? {
          maxWidth: isMobile ? "70%" : "40%",
          width: isMobile ? "70%" : "40%",
          height: "auto",
        }
      : {
          maxWidth: "auto",
          width: "auto",
          height: "auto",
        };

  useEffect(() => {
    const handleKeydown = (event) => {
      if (event.key === "Escape" && open) {
        onClose();
      }
    };

    document.addEventListener("keydown", handleKeydown);

    return () => {
      document.removeEventListener("keydown", handleKeydown);
    };
  }, [open, onClose]);

  return (
    <div
      onClick={onClose}
      className={`flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none transition-colors ${visible}`}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={`relative bg-white rounded-xl shadow p-6 transition-all ${scale}`}
        style={modalStyles}
      >
        <button
          className="absolute top-1 right-1 p-1 rounded-lg text-gray-400 bg-white hover:bg-gray-50 hover:text-gray-600"
          onClick={onClose}
        >
          X
        </button>
        {children}
      </div>
    </div>
  );
}
