import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import styled from "styled-components";
import { createClinicalNotes } from "../api.service";
import GenerateButton from "./GenerateButton";

const CreateClinicalNote = inject(
  "store",
  "navixScribeStore"
)(
  observer(({ store, navixScribeStore }) => {
    const [choices, setChoices] = useState([
      "SOAP",
      "DAP",
      "SIRP",
      "BIRP",
      "GIRP",
    ]);

    const [activeButton, setActiveButton] = useState("");

    const handleSetActiveButton = (e) => {
      setActiveButton(e.currentTarget.name);
    };

    const handleGenerateClinicalNotes = async () => {
      if (
        !navixScribeStore.inputActiveRecord.output &&
        !navixScribeStore.inputActiveRecord.outputs
      ) {
        return toast.error("Please select input first!");
      }

      if (activeButton === "") {
        return toast.error("Please select a clinical format");
      }

      const { data } = await createClinicalNotes(
        navixScribeStore.activeRecord,
        activeButton
      );

      navixScribeStore.setShowInputAccordion(false);
      navixScribeStore.setTriggerSection("ClinicalNote");
      navixScribeStore.setOutputActiveRecord(data.history);

      toast.success("Successfully generated clinical notes.");
    };

    useEffect(() => {
      const { inputActiveRecord } = navixScribeStore;
      const exists = choices.find((choice) => choice === "GROUP");
      let multipleSpeakers = false;

      if (inputActiveRecord) {
        if (inputActiveRecord.subToolAction === "audio") {
          if (inputActiveRecord.audioTranscriber.numberOfSpeakers > 1) {
            if (!exists) {
              multipleSpeakers = true;
            }
          }
        }
      }

      if (multipleSpeakers) {
        setChoices(() => [...choices, "GROUP"]);
      } else {
        setChoices(choices.filter((choice) => choice !== "GROUP"));

        if (activeButton === "GROUP") {
          setActiveButton("");
        }
      }
    }, [navixScribeStore.inputActiveRecord]);

    return (
      <>
        <div className="flex py-2 space-x-1 w-full overflow-x-auto mb-2">
          {choices.map((b, idx) => (
            <Button
              key={idx}
              name={b}
              className={b === activeButton ? "active" : ""}
              onClick={handleSetActiveButton}
            >
              {b}
            </Button>
          ))}
        </div>
        <GenerateButton generateFn={handleGenerateClinicalNotes} />
      </>
    );
  })
);

const Button = styled.button`
  border: 0.85px solid rgba(139, 35, 239, 0.38);
  border-radius: 4.26px;
  background-color: white;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  &.active {
    background-color: rgba(139, 35, 239, 1);
    color: white;
  }
`;

export default CreateClinicalNote;
