import { Component, useState, useRef, useCallback, useEffect } from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import { observable, makeObservable } from "mobx";
import { observer, inject } from "mobx-react";
import {
  MenuIcon,
  PlusSmIcon,
  ChatIcon,
  PencilAltIcon,
  TrashIcon,
  CheckIcon,
  XIcon,
  ClipboardIcon,
  UploadIcon,
  PaperAirplaneIcon,
  RefreshIcon,
  DotsVerticalIcon,
  ExclamationIcon,
} from "@heroicons/react/outline";
import { ThumbUpIcon, ThumbDownIcon } from "@heroicons/react/solid";
import TextareaAutosize from "react-textarea-autosize";
import toast from "react-hot-toast";
import Select from "react-select";
import moment from "moment";
import Header from "../Components/Header";

@inject("store")
@observer
class Tool extends Component {
  @observable tool = {};
  @observable profile = {};
  @observable isOpenLeftMenu = false;
  @observable isOpenRightMenu = false;
  @observable isNewChat = true;
  @observable chat = null;
  @observable chatId = null;
  @observable isLoadingMessages = false;
  @observable messages = [];
  @observable message = "";
  @observable selectedLibrary = "resource-library";
  @observable file = null;
  @observable selectedFiles = [];
  @observable unselectAllFiles = null;

  constructor(props) {
    super(props);

    makeObservable(this);

    this.tool = this.props.store.getToolByUrl(this.props.location.pathname);
    this.profile = JSON.parse(localStorage.getItem("profile"));

    if (!this.tool || !this.props.store.hasCurrentToolAccess()) {
      window.location.href = "/";
    }
  }

  handleOpenLeftMenu = (value) => {
    this.isOpenLeftMenu = value;
  };

  handleOpenRightMenu = (value) => {
    this.isOpenRightMenu = value;
  };

  handleNewChat = (value) => {
    this.isNewChat = value;
  };

  handleSetChat = (value) => {
    this.chat = value;
  };

  handleSetChatId = (value) => {
    this.chatId = value;
  };

  handleLoadingMessages = (value) => {
    this.isLoadingMessages = value;
  };

  handleSetMessages = (value) => {
    this.messages = value;
  };

  handleSetMessage = (value) => {
    this.message = value;
  };

  handleUploadFile = (value) => {
    this.file = value;
  };

  handleSelectLibrary = (value) => {
    this.selectedLibrary = value;
  };

  handleSelectFiles = (value) => {
    this.selectedFiles = value;
  };

  handleUnselectAllFiles = (value) => {
    this.unselectAllFiles = value;
  };

  scrollTo = (type) => {
    const messagesEl = document.getElementById("messages");
    let position = 0;

    if (type == "bottom") {
      position = messagesEl.scrollHeight;
    }

    messagesEl.scrollTop = position;
  };

  handleScrollToTop = () => {
    this.scrollTo("top");
  };

  handleScrollToBottom = () => {
    this.scrollTo("bottom");
  };

  render() {
    return (
      <>
        <Helmet>
          <title>{`${this.tool.title} - NavixAI`}</title>
        </Helmet>

        <Header
          title={this.tool.title}
          desc={this.tool.desc}
          Icon={this.tool.Icon}
          fromColor={this.tool.fromColor}
          category={this.tool.category}
        />

        <div className="px-4 pt-4 bg-gray-100">
          <div className="flex md:hidden justify-between mb-2">
            <button
              type="button"
              className="inline-flex"
              onClick={() => {
                this.handleOpenLeftMenu(true);
              }}
            >
              <MenuIcon className="h-6 w-6" />
            </button>

            <button
              type="button"
              className="inline-flex"
              onClick={() => {
                this.handleOpenRightMenu(true);
              }}
            >
              <MenuIcon className="h-6 w-6" />
            </button>

            {(this.isOpenLeftMenu || this.isOpenRightMenu) && (
              <div
                className="bg-gray-900 bg-opacity-50 fixed inset-0 z-30"
                onClick={() => {
                  this.handleOpenLeftMenu(false);
                  this.handleOpenRightMenu(false);
                }}
              ></div>
            )}
          </div>

          <div className="flex" style={{ height: "calc(100vh - 223px)" }}>
            <Chats
              tool={this.tool}
              show={this.isOpenLeftMenu}
              currentChat={this.chat}
              currentChatId={this.chatId}
              onOpenLeftMenu={this.handleOpenLeftMenu}
              onNewChat={this.handleNewChat}
              onSetChatId={this.handleSetChatId}
              onLoadingMessages={this.handleLoadingMessages}
              onSetMessages={this.handleSetMessages}
              onSetMessage={this.handleSetMessage}
              onUnselectAllFiles={this.handleUnselectAllFiles}
              onScrollToTop={this.handleScrollToTop}
            />

            <Messages
              tool={this.tool}
              profile={this.profile}
              isNewChat={this.isNewChat}
              chatId={this.chatId}
              currentlyLoadingMessages={this.isLoadingMessages}
              currentMessages={this.messages}
              currentMessage={this.message}
              selectedLibrary={this.selectedLibrary}
              selectedFiles={this.selectedFiles}
              onNewChat={this.handleNewChat}
              onSetChat={this.handleSetChat}
              onSetChatId={this.handleSetChatId}
              onUploadFile={this.handleUploadFile}
              onUnselectAllFiles={this.handleUnselectAllFiles}
              onScrollToBottom={this.handleScrollToBottom}
            />

            <Files
              tool={this.tool}
              show={this.isOpenRightMenu}
              profile={this.profile}
              uploadedFile={this.file}
              currentSelectedLibrary={this.selectedLibrary}
              unselectAllFiles={this.unselectAllFiles}
              onSelectLibrary={this.handleSelectLibrary}
              onSelectFiles={this.handleSelectFiles}
              onUnselectAllFiles={this.handleUnselectAllFiles}
            />
          </div>
        </div>
      </>
    );
  }
}

const Chats = inject("store")(({
  store,
  tool,
  show,
  currentChat,
  currentChatId,
  onOpenLeftMenu,
  onNewChat,
  onSetChatId,
  onLoadingMessages,
  onSetMessages,
  onSetMessage,
  onUnselectAllFiles,
  onScrollToTop,
}) => {
  const [advisors, setAdvisors] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [chats, setChats] = useState([]);
  const [chatsInGroup, setChatsInGroup] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [chatId, setChatId] = useState();
  const [chatName, setChatName] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [editChatId, setEditChatId] = useState();
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteChatId, setDeleteChatId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedAdvisor, setSelectedAdvisor] = useState(null);

  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (isLoading) return;

      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setCurrentPage((previousValue) => previousValue + 1);
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    [isLoading, hasMore]
  );

  const loadAdvisors = async () => {
    try {
      const url = tool.api.split("/");

      url.pop();

      const response = await store.api.get(url.join("/"));

      const data = response.data;

      if (data.success) {
        const advisors = data.result.advisors.map((advisor) => {
          url.push(advisor.slug);

          const advisor_url = url.join("/");

          url.pop();

          return {
            value: advisor_url,
            label: advisor.name,
          };
        });

        setAdvisors(advisors);
      } else {
        throw new Error("An error occurred");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const loadChats = async () => {
    setIsLoading(true);

    try {
      const response = await store.api.get(`${tool.api}/chats`, {
        params: {
          page: currentPage,
        },
      });

      const data = response.data;

      if (data.success) {
        const result = data.result;

        setChats((currentChats) => {
          return [...currentChats, ...result.chats];
        });

        setHasMore(currentPage < result.lastPage);
      } else {
        throw new Error("An error occurred");
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const loadChatsInGroup = () => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const inGroup = chats.reduce((previousValue, chat) => {
      const toTimeZone = new Intl.DateTimeFormat(undefined, {
        timeZone,
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      }).format(moment.utc(chat.updatedAt));

      const updatedAt = moment(new Date(toTimeZone));

      const groups = {
        Today: {
          start: moment().startOf("day"),
          end: moment().endOf("day"),
        },
        Yesterday: {
          start: moment().subtract(1, "day").startOf("day"),
          end: moment().subtract(1, "day").endOf("day"),
        },
        "Previous 7 Days": {
          start: moment().subtract(7, "day").startOf("day"),
          end: moment().subtract(2, "day").endOf("day"),
        },
        "[Month]": {
          start: moment().startOf("year"),
          end: moment().endOf("year"),
        },
        "[Month] [Year]": {
          start: moment().subtract(1, "year").startOf("year"),
          end: moment().subtract(1, "year").endOf("year"),
        },
      };

      let groupName = null;

      for (let [key, value] of Object.entries(groups)) {
        if (updatedAt.isBetween(value.start, value.end)) {
          switch (key) {
            case "[Month]":
              groupName = updatedAt.format("MMMM");
              break;
            case "[Month] [Year]":
              groupName = updatedAt.format("MMMM Y");
              break;
            default:
              groupName = key;
              break;
          }

          break;
        }
      }

      if (groupName) {
        if (!previousValue[groupName]) {
          previousValue[groupName] = [];
        }

        previousValue[groupName].push(chat);
      }

      return previousValue;
    }, {});

    const chatsInGroup = Object.keys(inGroup).map((group) => {
      return {
        group,
        chats: inGroup[group],
      };
    });

    setChatsInGroup(chatsInGroup);
  };

  const handleNewChat = () => {
    setChatId(null);
    setIsEditing(false);

    onOpenLeftMenu(false);
    onNewChat(true);
    onSetChatId(null);
    onScrollToTop();
    onSetMessages([]);
    onSetMessage("");
    onUnselectAllFiles(true);

    focusMessage();
  };

  const getMessages = async (chatId) => {
    setChatId(chatId);
    setIsEditing(false);

    onOpenLeftMenu(false);
    onNewChat(false);
    onSetChatId(chatId);
    onLoadingMessages(true);
    onSetMessages([]);
    onSetMessage("");
    onUnselectAllFiles(true);

    focusMessage();

    try {
      const response = await store.api.get(
        `${tool.api}/chats/${chatId}/messages`
      );

      const data = response.data;

      if (data.success) {
        const result = data.result;

        onSetMessages(result.messages);
      } else {
        throw new Error("An error occurred");
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      onLoadingMessages(false);
    }
  };

  const handleRenameChat = async (e) => {
    e.preventDefault();

    setIsUpdating(true);

    try {
      const response = await store.api.put(`${tool.api}/chats/${editChatId}`, {
        chatName,
      });

      const data = response.data;

      if (data.success) {
        updateChats(data.result.chat);

        toast.success("Successfully updated chat name.");
      } else {
        throw new Error("An error occurred");
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsEditing(false);
      setEditChatId(null);
      setIsUpdating(false);
    }
  };

  const handleDeleteChat = async (currentChatId) => {
    setIsDeleting(true);

    onNewChat(true);
    onSetMessage("");

    try {
      const response = await store.api.delete(
        `${tool.api}/chats/${currentChatId}`
      );

      const data = response.data;

      if (data.success) {
        deleteChat(currentChatId);

        onScrollToTop();

        if (currentChatId == chatId) {
          onSetMessages([]);
          onUnselectAllFiles(true);
        }

        toast.success("Successfully deleted chat.");
      } else {
        throw new Error("An error occurred");
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setChatId(null);
      setIsDeleting(false);
      setDeleteChatId(null);
      setShowDeleteModal(false);

      onSetChatId(null);
    }
  };

  const updateChats = (chat) => {
    deleteChat(chat._id);

    setChats((currentChats) => {
      return [chat, ...currentChats];
    });
  };

  const deleteChat = (chatId) => {
    setChats((currentChats) => {
      return currentChats.filter((chat) => {
        return chat._id != chatId;
      });
    });
  };

  const focusMessage = () => {
    document.getElementById("message").focus();
  };

  useEffect(() => {
    loadAdvisors();
  }, []);

  useEffect(() => {
    loadChats();
  }, [currentPage]);

  useEffect(() => {
    loadChatsInGroup();
  }, [chats]);

  useEffect(() => {
    if (currentChat) {
      updateChats(currentChat);
    }
  }, [currentChat]);

  useEffect(() => {
    if (currentChatId) {
      setChatId(currentChatId);
    }
  }, [currentChatId]);

  useEffect(() => {
    const advisorSlug = window.location.pathname.split("/").pop();
    const selectedAdvisor = advisors.find((advisor) =>
      advisor.value.includes(advisorSlug)
    );
    setSelectedAdvisor(selectedAdvisor);
  }, [advisors]);

  return (
    <>
      <div
        className={`${
          show ? "absolute top-0 left-0 z-40 h-full" : "hidden"
        } w-72 md:block bg-white p-4 overflow-y-auto space-y-2`}
      >
        <div className="space-y-2">
          <h3 className="font-medium">Select Advisor</h3>

          <Select
            value={selectedAdvisor}
            onChange={(selected) => {
              setSelectedAdvisor(selected);
              window.location = selected.value;
            }}
            options={advisors}
          />
        </div>

        <button
          type="button"
          className="w-full bg-gray-200 hover:bg-gray-300 font-medium text-sm p-2 inline-flex items-center border border-black rounded"
          onClick={() => {
            handleNewChat();
          }}
        >
          <PlusSmIcon className="h-6 w-6 mr-1" />
          New Chat
        </button>

        {chatsInGroup.map((row, groupIndex) => (
          <div
            key={groupIndex}
            className="space-y-2"
            {...(chatsInGroup.length == groupIndex + 1 && {
              ref: lastElementRef,
            })}
          >
            <h3 className="font-medium">{row.group}</h3>

            {row.chats.map((chat, chatIndex) => (
              <div
                key={chatIndex}
                className={`${
                  chat._id == chatId ? "bg-gray-300" : "bg-gray-200"
                } hover:bg-gray-300 border border-gray-300 rounded`}
              >
                {isEditing && chat._id == editChatId ? (
                  <form
                    className="w-full inline-flex"
                    onSubmit={handleRenameChat}
                  >
                    <input
                      type="text"
                      className="bg-gray-200 border border-gray-800 text-sm block w-full p-1"
                      value={chatName}
                      onChange={(e) => {
                        setChatName(e.target.value);
                      }}
                      autoFocus={true}
                    />

                    <button
                      type="submit"
                      className={`font-medium text-sm p-2 ${
                        isUpdating
                          ? "text-gray-400"
                          : "text-gray-500 hover:text-gray-600"
                      }`}
                      disabled={isUpdating}
                    >
                      {isUpdating ? (
                        <RefreshIcon className="h-5 w-5 animate-spin" />
                      ) : (
                        <CheckIcon className="h-5 w-5" />
                      )}
                    </button>

                    <button
                      type="button"
                      className={`font-medium text-sm p-2 ${
                        isUpdating
                          ? "text-gray-400"
                          : "text-gray-500 hover:text-gray-600"
                      }`}
                      disabled={isUpdating}
                      onClick={() => {
                        setChatName(null);
                        setIsEditing(false);
                        setEditChatId(null);
                      }}
                    >
                      <XIcon className="h-5 w-5" />
                    </button>
                  </form>
                ) : (
                  <div className="relative">
                    <div className="h-9">
                      <div
                        className="absolute top-2 left-2 cursor-pointer"
                        onClick={() => {
                          getMessages(chat._id);
                        }}
                      >
                        <ChatIcon className="h-5 w-5" />
                      </div>

                      <a
                        href="#"
                        className="font-medium text-sm p-2 pl-9 inline-flex items-start truncate w-full"
                        title={chat.name}
                        onClick={(e) => {
                          e.preventDefault();

                          getMessages(chat._id);
                        }}
                      >
                        {chat.name}
                      </a>
                    </div>

                    {chat._id == chatId && (
                      <div className="absolute top-0 right-0 bg-gray-300">
                        <button
                          type="button"
                          className="font-medium text-sm p-2 text-gray-500 hover:text-gray-600"
                          onClick={() => {
                            setChatName(chat.name);
                            setIsEditing(true);
                            setEditChatId(chat._id);
                          }}
                        >
                          <PencilAltIcon className="h-5 w-5" />
                        </button>

                        <button
                          type="button"
                          className="font-medium text-sm p-2 text-gray-500 hover:text-gray-600"
                          onClick={() => {
                            setDeleteChatId(chat._id);
                            setShowDeleteModal(true);
                          }}
                        >
                          <TrashIcon className="h-5 w-5" />
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}

        {isLoading && (
          <div className="animate-pulse">
            <div className="mb-2">
              <div className="h-6 w-24 bg-gray-300 rounded-full"></div>
            </div>

            <div className="space-y-2">
              <div className="h-8 bg-gray-300 rounded"></div>
              <div className="h-8 bg-gray-300 rounded"></div>
              <div className="h-8 bg-gray-300 rounded"></div>
            </div>
          </div>
        )}
      </div>

      {showDeleteModal && (
        <DeleteModal
          setShowModal={setShowDeleteModal}
          disableButtons={isDeleting}
          handleDelete={() => {
            handleDeleteChat(deleteChatId);
          }}
          handleCancel={() => {
            setDeleteChatId(null);
            setShowDeleteModal(false);
          }}
        />
      )}
    </>
  );
});

const Messages = inject("store")(({
  store,
  tool,
  profile,
  isNewChat,
  chatId,
  currentlyLoadingMessages,
  currentMessages,
  currentMessage,
  selectedLibrary,
  selectedFiles,
  onNewChat,
  onSetChat,
  onSetChatId,
  onUploadFile,
  onUnselectAllFiles,
  onScrollToBottom,
}) => {
  const [messages, setMessages] = useState([]);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [loadingOutput, setLoadingOutput] = useState(false);
  const [message, setMessage] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const allowedFiles = [
    "text/plain",
    "application/pdf",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];

  const handleCopyOutput = async (message) => {
    try {
      const humanloop_data_id = message.humanloop_data_id;

      const copyOutput = async () => {
        await navigator.clipboard.writeText(message.content);

        toast.success("Successfully copied to clipboard.");
      };

      if (humanloop_data_id) {
        const response = await store.api.post(
          `${tool.api}/chats/message/copy`,
          {
            humanloop_data_id,
          }
        );

        const data = response.data;

        if (data.success) {
          copyOutput();
        } else {
          throw new Error("An error occurred");
        }
      } else {
        copyOutput();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleRatingOutput = async (message, rating) => {
    try {
      const messageId = message._id;
      const humanloop_data_id = message.humanloop_data_id;

      const response = await store.api.post(
        `${tool.api}/chats/messages/rating`,
        {
          messageId,
          humanloop_data_id,
          rating,
        }
      );

      const data = response.data;

      if (data.success) {
        setMessages((currentMessages) => {
          return currentMessages.map((message) => {
            if (
              message._id == messageId ||
              message.humanloop_data_id == humanloop_data_id
            ) {
              message.rating = rating;
            }

            return message;
          });
        });
      } else {
        throw new Error("An error occurred");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleUploadFile = async (e) => {
    setIsUploading(true);

    try {
      const file = e.target.files[0];
      const maxSize = 1024 * 1024 * 50; // 50MB in bytes

      if (allowedFiles.includes(file.type)) {
        if (file.size < maxSize) {
          const formData = new FormData();

          formData.append("selectedLibrary", selectedLibrary);
          formData.append("file", file);

          const response = await store.api.post(`${tool.api}/files`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          const data = response.data;

          if (data.success) {
            const uploadedFile = data.result.file;

            onUnselectAllFiles(true);

            if (
              (profile.accountType == "admin" &&
                selectedLibrary == "resource-library") ||
              selectedLibrary == "my-library"
            ) {
              onUploadFile(uploadedFile);
            }

            toast.success("Successfully uploaded file.");
          } else {
            throw new Error("An error occurred");
          }
        } else {
          throw new Error("File size exceeds the 50MB limit.");
        }
      } else {
        throw new Error("File type not allowed");
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      e.target.value = null;

      setIsUploading(false);
    }
  };

  const handleSubmitMessage = async (e) => {
    e.preventDefault();

    const content = message.trim();

    try {
      if (content) {
        setLoadingOutput(true);

        onNewChat(false);

        const userMessage = {
          role: "user",
          content,
        };

        setMessages((currentMessages) => {
          return [...currentMessages, userMessage];
        });

        onScrollToBottom();

        const response = await store.api.post(tool.api, {
          chatId,
          message: userMessage,
          selectedFileIds: selectedFiles,
        });

        const data = response.data;

        if (data.success) {
          const result = data.result;

          const chat = result.chat;
          const chatId = chat._id;
          const assistantMessage = {
            role: "assistant",
            content: data.output,
            humanloop_data_id: data.feedbackId,
          };

          setMessages((currentMessages) => {
            return [...currentMessages, assistantMessage];
          });

          setMessage("");

          onSetChat(chat);
          onSetChatId(chatId);
          onScrollToBottom();
        } else {
          throw new Error("An error occurred");
        }
      } else {
        throw new Error("Message is required.");
      }
    } catch (error) {
      toast.error(error.message);

      if (content != "") {
        setMessages((currentMessages) => {
          return currentMessages.slice(0, -1);
        });
      }

      if (messages.length == 0) {
        onNewChat(true);
      }
    } finally {
      setLoadingOutput(false);
    }
  };

  const sanitizeOutput = (output) => {
    let content = {
      __html: output.replaceAll(/\r?\n/g, "<br>"),
    };

    return content;
  };

  useEffect(() => {
    setMessages(currentMessages);

    if (!isNewChat) {
      setLoadingMessages(currentlyLoadingMessages);
      onScrollToBottom();
    }

    setMessage(currentMessage);
  }, [currentlyLoadingMessages, currentMessages, currentMessage]);

  return (
    <div className="flex flex-col md:mx-4 bg-white w-full text-sm">
      <div id="messages" className="h-full overflow-y-auto">
        {isNewChat ? (
          <div className="p-8">
            <h1 className="text-xl font-bold mb-4">
              What can you help me with?
            </h1>

            <p
              dangerouslySetInnerHTML={sanitizeOutput(tool.welcomeMessage)}
            ></p>
          </div>
        ) : (
          <>
            {messages.map((message, index) => (
              <div key={index}>
                {message.role == "user" ? (
                  <div className="bg-gray-100 px-8 py-3 border-t-2 border-b-2 border-gray-200 flex">
                    <div>
                      <span className="bg-gray-800 inline-flex items-center justify-center h-8 w-8 rounded-full">
                        <span className="text-s text-white leading-none">
                          {profile.fname.toUpperCase().charAt(0)}
                        </span>
                      </span>
                    </div>

                    <div className="ml-2">{message.content}</div>
                  </div>
                ) : (
                  <div className="px-8 py-3">
                    <div className="flex justify-end mb-3">
                      <ClipboardIcon
                        className="h-6 w-6 inline cursor-pointer text-gray-500"
                        onClick={() => {
                          handleCopyOutput(message);
                        }}
                      />

                      <ThumbUpIcon
                        className={`h-6 w-6 inline cursor-pointer ${
                          message.rating == "good"
                            ? "text-green-500"
                            : "text-gray-500 hover:text-green-500"
                        }`}
                        onClick={() => {
                          handleRatingOutput(message, "good");
                        }}
                      />

                      <ThumbDownIcon
                        className={`h-6 w-6 inline cursor-pointer ${
                          message.rating == "bad"
                            ? "text-red-500"
                            : "text-gray-500 hover:text-red-500"
                        }`}
                        onClick={() => {
                          handleRatingOutput(message, "bad");
                        }}
                      />
                    </div>

                    <div>
                      <p
                        dangerouslySetInnerHTML={sanitizeOutput(
                          message.content
                        )}
                      ></p>
                    </div>
                  </div>
                )}
              </div>
            ))}

            {(loadingMessages || loadingOutput) && (
              <div className="animate-pulse">
                {loadingMessages && (
                  <div className="bg-gray-100 px-8 py-3 border-t-2 border-b-2 border-gray-200 flex">
                    <div>
                      <span className="bg-gray-300 inline-flex items-center justify-center h-8 w-8 rounded-full"></span>
                    </div>

                    <div className="ml-2 w-full">
                      <div className="h-4 bg-gray-300 rounded-full"></div>
                    </div>
                  </div>
                )}

                <div className="px-8 py-3">
                  <div className="flex justify-end mb-3">
                    <div className="h-6 w-24 bg-gray-300 rounded-full"></div>
                  </div>

                  <div className="space-y-3">
                    <div className="h-4 bg-gray-300 rounded-full"></div>
                    <div className="h-4 bg-gray-300 rounded-full"></div>
                    <div className="h-4 bg-gray-300 rounded-full"></div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <div className="h-30">
        <form onSubmit={handleSubmitMessage}>
          <div className="flex items-center border-2 rounded-md m-4 shadow">
            <TextareaAutosize
              id="message"
              minRows={3}
              maxRows={5}
              autoFocus={true}
              className="resize-none w-full block p-2 leading-4 outline-none"
              placeholder="Write a message..."
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
              onKeyDown={(e) => {
                if (("New Line", e.key == "Enter" && !e.shiftKey)) {
                  handleSubmitMessage(e);
                }
              }}
            />

            <input
              type="file"
              id="document"
              className="hidden"
              accept={allowedFiles.join(",")}
              onChange={handleUploadFile}
            />

            <button
              type="button"
              className={`h-8 w-10 ml-2 mr-1 ${
                isUploading
                  ? "text-gray-400"
                  : "text-gray-500 hover:text-gray-600"
              }`}
              disabled={isUploading}
              onClick={() => {
                document.getElementById("document").click();
              }}
            >
              {isUploading ? (
                <RefreshIcon className="h-6 w-6 inline animate-spin" />
              ) : (
                <UploadIcon className="h-6 w-6 inline" />
              )}
            </button>

            <button
              type="submit"
              className={`h-8 w-10 ml-1 mr-2 ${
                loadingOutput
                  ? "text-gray-400"
                  : "text-gray-500 hover:text-gray-600"
              } align-middle`}
              disabled={loadingOutput}
            >
              {loadingOutput ? (
                <RefreshIcon className="h-6 w-6 inline animate-spin" />
              ) : (
                <PaperAirplaneIcon className="h-6 w-6 rotate-90 inline" />
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
});

const Files = inject("store")(({
  store,
  tool,
  show,
  profile,
  uploadedFile,
  currentSelectedLibrary,
  unselectAllFiles,
  onSelectLibrary,
  onSelectFiles,
  onUnselectAllFiles,
}) => {
  const [selectedLibrary, setSelectedLibrary] = useState(
    currentSelectedLibrary
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showDropdownMenu, setShowDropdownMenu] = useState(false);
  const [dropdownMenuId, setDropdownMenuId] = useState();
  const [isUpdating, setIsUpdating] = useState(false);
  const [editFileId, setEditFileId] = useState();
  const [filename, setFilename] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteFileId, setDeleteFileId] = useState();
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (isLoading) return;

      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setCurrentPage((previousValue) => previousValue + 1);
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    [isLoading, hasMore]
  );

  const loadFiles = async (selectedLibrary) => {
    setIsLoading(true);

    try {
      const response = await store.api.get(`${tool.api}/files`, {
        params: {
          selectedLibrary,
          page: currentPage,
        },
      });

      const data = response.data;

      if (data.success) {
        const result = data.result;

        setFiles((currentFiles) => {
          return [...currentFiles, ...result.files];
        });

        setHasMore(currentPage < result.lastPage);
      } else {
        throw new Error("An error occurred");
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectLibrary = (selectedLibrary) => {
    setSelectedLibrary(selectedLibrary);
    setFiles([]);
    setSelectedFiles([]);

    onSelectLibrary(selectedLibrary);

    loadFiles(selectedLibrary);
  };

  const handleSelectFiles = (checked, fileId) => {
    setSelectedFiles((currentSelectedFiles) => {
      if (checked) {
        return [...currentSelectedFiles, fileId];
      } else {
        return currentSelectedFiles.filter(
          (currentFileId) => currentFileId != fileId
        );
      }
    });

    onUnselectAllFiles(false);
  };

  const handleRenameFile = async (fileId) => {
    setIsUpdating(true);

    try {
      const response = await store.api.put(`${tool.api}/files/${fileId}`, {
        displayName: filename,
      });

      const data = response.data;

      if (data.success) {
        setFiles((currentFiles) => {
          return currentFiles.map((file) => {
            if (file._id == fileId) {
              file.displayName = filename;
            }

            return file;
          });
        });

        toast.success("Successfully updated filename.");
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsUpdating(false);
      setEditFileId(null);
      setFilename(null);
      setShowRenameModal(false);
    }
  };

  const handleDeleteFile = async (fileId) => {
    setIsDeleting(true);

    try {
      const response = await store.api.delete(`${tool.api}/files/${fileId}`);

      const data = response.data;

      if (data.success) {
        setFiles([]);
        setSelectedFiles([]);

        loadFiles(selectedLibrary);

        toast.success("Successfully deleted file.");
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsDeleting(false);
      setDeleteFileId(null);
      setShowDeleteModal(false);
    }
  };

  useEffect(() => {
    loadFiles(selectedLibrary);
  }, [currentPage]);

  useEffect(() => {
    if (uploadedFile) {
      setFiles((currentFiles) => {
        return [uploadedFile, ...currentFiles];
      });
    }
  }, [uploadedFile]);

  useEffect(() => {
    onSelectFiles(selectedFiles);
  }, [selectedFiles]);

  useEffect(() => {
    if (unselectAllFiles) {
      setSelectedFiles([]);
    }
  }, [unselectAllFiles]);

  return (
    <>
      <div
        className={`${
          show ? "absolute top-0 right-0 z-40 h-full" : "hidden"
        } w-96 md:block bg-white p-4 overflow-y-auto space-y-2`}
      >
        <div className="w-full inline-flex rounded shadow-sm" role="group">
          <button
            type="button"
            className={`w-1/2 ${
              selectedLibrary == "resource-library"
                ? "bg-gray-300"
                : "bg-gray-200"
            } hover:bg-gray-300 font-medium text-sm p-2 border border-gray-300 rounded-l`}
            onClick={() => {
              handleSelectLibrary("resource-library");
            }}
          >
            Resource Library
          </button>

          <button
            type="button"
            className={`w-1/2 ${
              selectedLibrary == "my-library" ? "bg-gray-300" : "bg-gray-200"
            } hover:bg-gray-300 font-medium text-sm p-2 border border-gray-300 rounded-r`}
            onClick={() => {
              handleSelectLibrary("my-library");
            }}
          >
            My Library
          </button>
        </div>

        <div className="space-y-2">
          {files.map((file, index) => (
            <div
              key={file._id}
              className="flex items-center"
              {...(files.length == index + 1 && {
                ref: lastElementRef,
              })}
            >
              <div className="mr-2">
                <input
                  type="checkbox"
                  id={`file-${file._id}`}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded cursor-pointer"
                  checked={selectedFiles.includes(file._id)}
                  onChange={(e) => {
                    handleSelectFiles(e.target.checked, file._id);
                  }}
                />
              </div>

              <div className="flex-col w-full">
                <div className="flex">
                  <div className="w-full">
                    <label
                      htmlFor={`file-${file._id}`}
                      className="text-sm font-medium cursor-pointer break-all"
                      title={file.displayName}
                    >
                      {file.displayName}
                    </label>
                  </div>

                  {(selectedLibrary == "my-library" ||
                    profile.accountType == "admin") && (
                    <div className="ml-2 relative">
                      <button
                        type="button"
                        className="inline-flex items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100"
                        onClick={() => {
                          setShowDropdownMenu(!showDropdownMenu);
                          setDropdownMenuId(file._id);
                        }}
                      >
                        <DotsVerticalIcon className="w-4 h-4" />
                      </button>

                      {showDropdownMenu && dropdownMenuId == file._id && (
                        <div>
                          <div
                            className="fixed inset-0 z-10"
                            onClick={() => {
                              setShowDropdownMenu(false);
                            }}
                          ></div>

                          <div className="absolute right-0 z-20 bg-white divide-y divide-gray-100 rounded-lg shadow w-44">
                            <ul className="py-2 text-sm text-gray-700">
                              <li>
                                <a
                                  href="#"
                                  className="block px-4 py-2 hover:bg-gray-100"
                                  onClick={(e) => {
                                    e.preventDefault();

                                    setShowDropdownMenu(false);
                                    setEditFileId(file._id);
                                    setFilename(file.displayName);
                                    setShowRenameModal(true);
                                  }}
                                >
                                  Rename
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#"
                                  className="block px-4 py-2 hover:bg-gray-100"
                                  onClick={(e) => {
                                    e.preventDefault();

                                    setShowDropdownMenu(false);
                                    setDeleteFileId(file._id);
                                    setShowDeleteModal(true);
                                  }}
                                >
                                  Delete
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div className="flex justify-between">
                  <small className="text-gray-500">{file.size}</small>

                  <small className="text-gray-500" title={file.createdAt}>
                    {file.createdAtFromNow}
                  </small>
                </div>
              </div>
            </div>
          ))}
        </div>

        {isLoading && (
          <div className="animate-pulse">
            <div className="space-y-2">
              <div className="h-12 bg-gray-300 rounded"></div>
              <div className="h-12 bg-gray-300 rounded"></div>
              <div className="h-12 bg-gray-300 rounded"></div>
            </div>
          </div>
        )}
      </div>

      {showRenameModal && (
        <RenameModal
          setShowModal={setShowRenameModal}
          value={filename}
          disableButtons={isUpdating}
          handleValue={(e) => {
            setFilename(e.target.value);
          }}
          handleRename={() => {
            handleRenameFile(editFileId);
          }}
          handleCancel={() => {
            setEditFileId(null);
            setFilename(null);
            setShowRenameModal(false);
          }}
        />
      )}

      {showDeleteModal && (
        <DeleteModal
          setShowModal={setShowDeleteModal}
          disableButtons={isDeleting}
          handleDelete={() => {
            handleDeleteFile(deleteFileId);
          }}
          handleCancel={() => {
            setDeleteFileId(null);
            setShowDeleteModal(false);
          }}
        />
      )}
    </>
  );
});

const DeleteModal = ({
  setShowModal,
  disableButtons,
  handleDelete,
  handleCancel,
}) => (
  <Modal
    setShowModal={setShowModal}
    disableButtons={disableButtons}
    handleCancel={handleCancel}
  >
    <ExclamationIcon className="h-14 w-14 mx-auto text-red-500" />

    <h3 className="mb-5 text-lg font-normal">
      Are you sure you want to delete?
    </h3>

    <button
      type="button"
      className="hover:text-white border border-red-200 hover:bg-red-500 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
      disabled={disableButtons}
      onClick={handleDelete}
    >
      {disableButtons && <RefreshIcon className="h-3 w-3 animate-spin" />}
      Yes, I'm sure
    </button>
  </Modal>
);

const RenameModal = ({
  setShowModal,
  value,
  disableButtons,
  handleValue,
  handleRename,
  handleCancel,
}) => (
  <Modal
    title={"Rename"}
    setShowModal={setShowModal}
    disableButtons={disableButtons}
    handleCancel={handleCancel}
  >
    <div className="mb-4">
      <input
        type="text"
        className="bg-gray-50 border border-gray-300 text-sm block w-full p-1"
        value={value}
        onChange={handleValue}
        autoFocus={true}
      />
    </div>

    <button
      type="button"
      className="hover:text-white border border-gray-200 hover:bg-gray-500 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
      disabled={disableButtons}
      onClick={handleRename}
    >
      {disableButtons && <RefreshIcon className="h-3 w-3 animate-spin" />}
      Save
    </button>
  </Modal>
);

const Modal = ({
  children,
  title,
  setShowModal,
  disableButtons,
  showCancelButton = true,
  handleCancel = null,
}) => {
  if (handleCancel == null) {
    handleCancel = () => setShowModal(false);
  }

  return (
    <div className="flex justify-center items-center bg-gray-900 bg-opacity-50 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-96 my-6 mx-auto">
        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex items-start justify-between p-5 pb-0 border-gray-300 rounded-t">
            <h3 className="mb-5 text-lg font-normal">{title}</h3>

            <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              disabled={disableButtons}
              onClick={() => setShowModal(false)}
            >
              <XIcon className="h-5 w-5" />
            </button>
          </div>

          <div className="p-6 pt-0 text-center">
            {children}

            {showCancelButton && (
              <button
                type="button"
                className="bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900"
                disabled={disableButtons}
                onClick={handleCancel}
              >
                Cancel
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Tool);
