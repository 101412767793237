import { inject, observer } from "mobx-react";
import { Suspense, lazy, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../../Components/Header";
import { AccordionList } from "./components/AccordionList";
import CreateClinicalNote from "./components/CreateClinicalNote";
import Inputs from "./components/Inputs";
import Loader from "../../Components/Loader";
import AskAQuestion from "./components/AskAQuestion";
import CreateSummary from "./components/CreateSummary";
import { Persona } from "./components/Persona";
import Modal from "../../Components/Modal";
import { BookOpenIcon } from "@heroicons/react/outline";

const Output = lazy(() => import("./components/Output"));

const defaultAccordion = [
  {
    Component: Inputs,
    title: "Inputs",
    description: "Upload Audio File or Text File",
    open: true,
    toolType: "NavixScribe",
    toolAction: "Input",
  },
  {
    Component: CreateClinicalNote,
    title: "Create Clinical Note",
    description: "Select Format",
    open: false,
    toolType: "NavixScribe",
    toolAction: "ClinicalNote",
  },
  {
    Component: AskAQuestion,
    title: "Ask a Question",
    description: "Ask me a question",
    open: false,
    toolType: "NavixScribe",
    toolAction: "AskQuestion",
  },
  {
    Component: CreateSummary,
    title: "Create a Summary",
    description: "Create a summary of the given inputs",
    open: false,
    toolType: "NavixScribe",
    toolAction: "Summary",
  },
];

const NavixScribe = inject("store")(
  observer(({ store, navixScribeStore, location }) => {
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [videoSrc, setVideoSrc] = useState(
      "https://www.youtube.com/embed/WAGrTtfEkGA?si=0dHmvv0dK-3uQTaL"
    );

    useEffect(() => {
      if (!store.hasCurrentToolAccess()) {
        window.location.href = "/";
      }

      navixScribeStore.setTool(store.getToolByUrl(location.pathname));
      return () => {
        navixScribeStore.clear();
      };
    }, []);

    useEffect(() => {
      const checkFirstInteraction = async () => {
        if (store.profile && !store.profile.firstNavixScribeInteraction) {
          setShowModal(true);
          await store.api.patch(`/user/${store.profile._id}`, {
            firstNavixScribeInteraction: true,
          });
        }
      };
      checkFirstInteraction();
    }, [store.profile]);

    useEffect(() => {
      const abortController = new AbortController();
      const signal = abortController.signal;
      if (navixScribeStore.activeRecord !== "") {
        (async () => {
          try {
            setLoading(true);
            const { data } = await store.api.get(
              `/histories/navix-scribe/${navixScribeStore.activeRecord}`,
              { signal }
            );
            const history = data.history;

            navixScribeStore.setInputDocument(null);

            if (navixScribeStore.activeSection === "Input") {
              if (history.subToolAction === "text" && history.filename) {
                const document = await store.api({
                  url: `/histories/navix-scribe/${navixScribeStore.activeRecord}/document`,
                  responseType: "arraybuffer",
                  responseEncoding: "binary",
                  headers: {
                    "Content-Type": "application/pdf",
                  },
                });

                history.output = null;

                navixScribeStore.setInputDocument(document);
              }

              navixScribeStore.setInputActiveRecord(history);
              navixScribeStore.setOutputActiveRecord("");
            } else {
              navixScribeStore.setOutputActiveRecord(history);
            }
          } catch (e) {
            console.log(e);
          } finally {
            setTimeout(() => {
              setLoading(false);
            }, 500);
          }
        })();
      }

      return () => {
        abortController.abort();
      };
    }, [navixScribeStore.activeRecord]);

    const closeModal = () => {
      setShowModal(false);
      setVideoSrc("");
    };

    const openModal = () => {
      setVideoSrc(
        "https://www.youtube.com/embed/WAGrTtfEkGA?si=-C2v-KGAlpx4C3s9"
      );
      setShowModal(true);
    };

    return (
      <div>
        <Helmet>
          <title>{`${navixScribeStore.tool.title} - NavixAI`}</title>
        </Helmet>

        <Header
          title={navixScribeStore.tool.title}
          desc={navixScribeStore.tool.desc}
          Icon={navixScribeStore.tool.Icon}
          fromColor={navixScribeStore.tool.fromColor}
          category={navixScribeStore.tool.category}
          showGuideButton={true}
          onGuideButtonClick={openModal}
        />

        <div className="p-4 bg-gray-100 h-full grid lg:grid-cols-12 lg:gap-4">
          <div
            className="lg:col-span-4 overflow-y-auto"
            style={{
              maxHeight: "78vh",
              overflowY: "auto",
            }}
          >
            <AccordionList
              data={defaultAccordion}
              navixScribeStore={navixScribeStore}
            />

            <Persona />
          </div>

          <div
            className="lg:col-span-8 rounded-md border p-4 bg-white shadow-lg relative overflow-y-auto"
            style={{
              maxHeight: "78vh",
            }}
          >
            <Suspense
              fallback={
                <div className="flex justify-center items-center align-middle h-full">
                  <div>
                    <Loader active={true} className="w-36 h-36" />
                    <p className="text-xs text-gray-500">
                      Please wait for a moment...
                    </p>
                  </div>
                </div>
              }
            >
              <Output loading={loading} />
            </Suspense>
          </div>
        </div>

        <Modal open={showModal} onClose={closeModal} variant="video">
          <div className="flex items-center mb-2">
            <BookOpenIcon className="h-6 w-6 mr-2 text-gray-800" />
            <h2 className="text-xl font-bold">Guide</h2>
          </div>
          <p className="mb-2">
            Learn how to get started with NavixScribe by watching this video:
          </p>
          <div
            className="relative w-full"
            style={{
              paddingBottom: "56.25%",
              height: 0,
              overflow: "hidden",
              maxWidth: "100%",
              background: "#000",
            }}
          >
            <iframe
              src={videoSrc}
              frameBorder="0"
              allowFullScreen
              title="NavixScribe guide"
              className="absolute top-0 left-0 w-full h-full"
              style={{ border: 0 }}
            ></iframe>
          </div>
        </Modal>
      </div>
    );
  })
);

export default NavixScribe;
