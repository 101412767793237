const dev = {
  baseURL: "http://localhost:3080/api/",
  landingPageUrl: "http://localhost:3080",
  stripe: {
    credits10: "price_1NOdKvI7qqcMOF8zonAHmyBs",
    free: "price_1MiuVnI7qqcMOF8zzY29SjfC",
    entry: "price_1MivVwI7qqcMOF8zLwsAjvYV",
    pro: "price_1MivWJI7qqcMOF8zWRuJmi39",
  },
};

const prod = {
  // baseURL: 'https://navixai-api.azurewebsites.net/api/',
  baseURL: "/api/",
  // landingPageUrl: "https://ai.navixhealth.com",
  landingPageUrl: "https://navixai-main.azurewebsites.net/",
  stripe: {
    credits10: "price_1NOdKvI7qqcMOF8zonAHmyBs",
    free: "price_1MiuVnI7qqcMOF8zzY29SjfC",
    entry: "price_1Ml9xvI7qqcMOF8z8iH5bmaZ",
    pro: "price_1Ml9xGI7qqcMOF8zvwetxAdx",
  },
};

const config = process.env.NODE_ENV === "development" ? dev : prod;

export default config;
