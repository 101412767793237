import advisors from "./advisors";
import talktodatabase from "./talktodatabase";
import audios from "./audio";
import clinicalnoteswriter from "./clinicalnoteswriter";
import progressnoterewriter from "./progressnoterewriter";
import summarize from "./summarize";
import blogwriter from "./blogwriter";
import medicalcoding from "./medicalcoding";

const TOOLS = [
  ...advisors,
  talktodatabase,
  ...audios,
  clinicalnoteswriter,
  progressnoterewriter,
  summarize,
  blogwriter,
  medicalcoding,
];

export default TOOLS;
