import { inject, observer } from "mobx-react";
import { useState } from "react";
import toast from "react-hot-toast";
import styled from "styled-components";
import Loader from "../../../Components/Loader";

const GenerateButton = inject(
  "store",
  "navixScribeStore"
)(
  observer(({ generateFn, label = "Generate" }) => {
    const [loading, setLoading] = useState(false);

    const handleGenerateClinicalNotes = async () => {
      setLoading(true);
      try {
        await generateFn();
      } catch (err) {
        console.log(err);
        toast.error("Something went wrong.");
      } finally {
        setLoading(false);
      }
    };

    return (
      <>
        <Button
          className="mr-auto py-2 px-12 flex items-center"
          onClick={handleGenerateClinicalNotes}
          disabled={loading}
        >
          <Loader active={loading} className="w-6 h-6 mr-1" />
          {label}
        </Button>
      </>
    );
  })
);

const Button = styled.button`
  border-radius: 5.89px;
  background: rgba(139, 35, 239, 1);
  padding: 0.5rem 2rem;
  color: white;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;

  &:hover {
    background: rgba(139, 35, 239, 0.9);
  }

  &:active {
    background: rgb(92, 40, 160);
  }

  &:disabled {
    cursor: not-allowed;
    background: #451e78;
  }
`;

export default GenerateButton;
