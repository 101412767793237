// const Logo = (props) => (
// 	<svg className={props.className || "w-24 h-24"} viewBox="0 0 148 170" fill="none" xmlns="http://www.w3.org/2000/svg">
// 	<rect x="6.46851" y="79.6786" width="100" height="100" transform="rotate(-45 6.46851 79.6786)" fill="url(#paint0_linear)"/>
// 	<circle cx="48.49" cy="47.4901" r="22.9739" transform="rotate(-45 48.49 47.4901)" fill="url(#paint1_linear)"/>
// 	<circle cx="129.135" cy="116.135" r="8.58055" transform="rotate(-45 129.135 116.135)" fill="url(#paint2_linear)"/>
// 	<rect x="74" y="104" width="48" height="48" transform="rotate(-90 74 104)" fill="url(#paint3_linear)"/>
// 	<rect y="57.0001" width="19" height="19" transform="rotate(-90 0 57.0001)" fill="url(#paint4_linear)"/>
// 	<path d="M18.4354 134.084L63.0843 89.4355L79.4269 150.427L18.4354 134.084Z" fill="url(#paint5_linear)"/>
// 	<path d="M101.652 26.6642L123.664 4.65179L131.721 34.7213L101.652 26.6642Z" fill="#4029B5"/>
// 	<defs>
// 	<linearGradient id="paint0_linear" x1="56.4685" y1="79.6786" x2="56.4685" y2="179.679" gradientUnits="userSpaceOnUse">
// 	<stop stopColor="#7B61FF"/>
// 	<stop offset="1" stopColor="#2103BA"/>
// 	</linearGradient>
// 	<linearGradient id="paint1_linear" x1="48.49" y1="24.5162" x2="48.49" y2="70.464" gradientUnits="userSpaceOnUse">
// 	<stop stopColor="#46E1C5"/>
// 	<stop offset="1" stopColor="#00836B"/>
// 	</linearGradient>
// 	<linearGradient id="paint2_linear" x1="129.135" y1="107.554" x2="129.135" y2="124.715" gradientUnits="userSpaceOnUse">
// 	<stop stopColor="#46E1C5"/>
// 	<stop offset="1" stopColor="#00836B"/>
// 	</linearGradient>
// 	<linearGradient id="paint3_linear" x1="98" y1="104" x2="98" y2="152" gradientUnits="userSpaceOnUse">
// 	<stop stopColor="#FFEC40"/>
// 	<stop offset="1" stopColor="#FFB931"/>
// 	</linearGradient>
// 	<linearGradient id="paint4_linear" x1="9.5" y1="57.0001" x2="9.5" y2="76.0001" gradientUnits="userSpaceOnUse">
// 	<stop stopColor="#FFC961"/>
// 	<stop offset="1" stopColor="#FFE600"/>
// 	</linearGradient>
// 	<linearGradient id="paint5_linear" x1="18.4354" y1="134.084" x2="74.5" y2="119" gradientUnits="userSpaceOnUse">
// 	<stop stopColor="#4029B5"/>
// 	<stop offset="1" stopColor="#130076"/>
// 	</linearGradient>
// 	</defs>
//   </svg>
//   )

//   export default Logo

const Logo = (props) => (
  <svg
    width="92px"
    height="92px"
    viewBox="0 0 92 92"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Style-Guide"
        transform="translate(-135.000000, -34.000000)"
        fillRule="nonzero"
      >
        <g
          id="Navix_icon_light-Copy"
          transform="translate(135.000000, 34.000000)"
        >
          <polygon
            id="Path"
            fill="#E8E4E1"
            points="0 45.73324 25.00891 60.01812 16.84958 45.73324 25.00891 31.44696"
          ></polygon>
          <polygon
            id="Path"
            fill="#A18292"
            points="60.01766 25.00937 45.73137 0 31.44696 25.00937 45.73137 16.84958"
          ></polygon>
          <polygon
            id="Path"
            fill="#9DB6AF"
            points="31.44696 66.45524 45.73137 91.46461 60.01766 66.45524 45.73137 74.61503"
          ></polygon>
          <polygon
            id="Path"
            fill="#C2986B"
            points="66.4557 31.44696 74.61503 45.73324 66.4557 60.01812 91.46461 45.73324"
          ></polygon>
        </g>
      </g>
    </g>
  </svg>
);

export default Logo;
